














































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  ApiDoctorArticle,
  ApiDoctorArticleDel,
  ApiDoctorArticlePublished,
  ApiDoctorArticleUnpublished,
} from "@/apis";
import { breadcrumb } from "@/utils/breadcrumb";
import ConsultationIndex from "@/views/Supervise/Consultation/index.vue";
import { component } from "vue/types/umd";
import moment from "moment";

@Component({
  components: {},
})
export default class DoctorArticle extends Vue {
  tableLoading = false;
  // 查询条件
  query: any = {
    // doctor_name: "",
    module: null,
    title: "",
    start_time: "",
    end_time: "",
    order_weight: null,
  };
  methodTypeList = [
    {
      label: "小程序",
      value: "101",
    },
    {
      label: "H5",
      value: "102",
    },
  ];
  // 类型
  typeList: Array<any> = [
    { value: null, label: "全部" },
    { value: "101", label: "嘉医云诊室小程序" },
  ];
  moduleList: Array<any> = [
    { value: null, label: "全部" },
    { value: "100", label: "科普" },
    { value: "101", label: "视频" },
    { value: "102", label: "直播" },
  ];
  orderWeightList: Array<any> = [
    { value: null, label: "全部" },
    { value: "1", label: "是" },
    { value: "0", label: "否" },
  ];
  typeStatus = (status: number) => {
    switch (status) {
      case 101:
        return "嘉医云诊室小程序";
      default:
        return "-";
    }
  };
  moduleStatus = (status: number) => {
    switch (status) {
      case 100:
        return "科普";
      case 101:
        return "视频";
      case 102:
        return "直播";
      default:
        return "-";
    }
  };

  //时间格式处理
  dateFormat = function (row: any) {
    if (row === undefined) {
      return "-";
    }

    const moment = require("moment");
    return moment(row).format("YYYY-MM-DD");
  };

  // 数据
  tableData: Array<any> = [];
  //表格分页器变量
  pagination = {
    //分页器当前页显示几条数据
    size: 10,
    //表格的数据总量
    total: 0,
    //当前是第几页
    page: 1,
  };

  dash(data: any) {
    if (data === null || data === "" || data === undefined) {
      data = "-";
    }
    return data;
  }
  mounted() {
    this.doctorArticle();
  }
  search() {
    this.$set(this.pagination, "page", 1);
    this.doctorArticle();
  }

  resetClick() {
    this.pagination.size = 10;
    this.pagination.page = 1;
    Object.keys(this.query).forEach((key: any) => {
      this.query[key] = null;
    });
    this.doctorArticle();
  }

  async doctorArticle() {
    const param = JSON.parse(JSON.stringify(this.query)) as any;

    param.cur_page = this.pagination.page;
    param.page_size = this.pagination.size;

    if (param.end_time) {
      // 结束时间添加时间为23:59:59;
      param.end_time = param.end_time + (24 * 60 * 60 * 1000 - 1000);
    }

    ApiDoctorArticle(param).then((res) => {
      this.tableData = res.data;
      this.pagination.total = res.page.total_size;
      this.tableLoading = false;
    });
  }
  //分页器改变页显示数触发函数
  handleSizeChange(val: any) {
    this.pagination.size = val;
    this.doctorArticle();
  }
  //分页器改变当前显示页触发函数
  handleCurrentChange(val: any) {
    this.pagination.page = val;
    this.doctorArticle();
  }

  increaseButtonClick(row: any) {
    breadcrumb.push(this, "/doctor/add", "新增医生内容", {}, false);
  }
  delArticle(row: any) {
    this.$confirm("确认删除嘛？", "提示", {
      confirmButtonText: "确认",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      ApiDoctorArticleDel(row.id).then((res) => {
        if (res.success) {
          this.$message.success("删除成功");
          this.doctorArticle();
        }
      });
    });
  }
  publishArticle(row: any) {
    this.$confirm("确认上架嘛？", "提示", {
      confirmButtonText: "确认",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      ApiDoctorArticlePublished(row.id).then((res) => {
        if (res.success) {
          this.$message.success("上架成功");
          this.doctorArticle();
        }
      });
    });
  }
  unPublishArticle(row: any) {
    this.$confirm("确认下架嘛？", "提示", {
      confirmButtonText: "确认",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      ApiDoctorArticleUnpublished(row.id).then((res) => {
        if (res.success) {
          this.$message.success("下架成功");
          this.doctorArticle();
        }
      });
    });
  }
  editArticle(row: any) {
    breadcrumb.push(
      this,
      "/doctor/edit",
      "编辑医生内容",
      { row: JSON.stringify(row) },
      false
    );
  }
}
